import axios from "axios";
import { setLoader } from "../redux/loader/loaderActions";
import RequestInterceptor from "./requestInterceptor";

const http_lib = axios.create({});

// http_lib.defaults.headers.common["ngrok-skip-browser-warning"] = "69420"
// http_lib.defaults.headers.common["access-control-allow-origin"] = '*'
http_lib.interceptors.request.use(RequestInterceptor);

export function Post(url, body, dispatch) {
  dispatch(setLoader(true));
  return new Promise((resolve, reject) => {
    http_lib
      .post(url, body)
      .then((res) => {
        dispatch(setLoader(false));
        return resolve(res);
      })
      .catch((err) => {
        dispatch(setLoader(false));
        return reject(err);
      });
  });
}

export function Put(url, body, dispatch) {
  dispatch(setLoader(true));
  return new Promise((resolve, reject) => {
    http_lib
      .put(url, body)
      .then((res) => {
        dispatch(setLoader(false));
        return resolve(res);
      })
      .catch((err) => {
        dispatch(setLoader(false));
        return reject(err);
      });
  });
}

export function Get(url, dispatch) {
  dispatch(setLoader(true));
  return new Promise((resolve, reject) => {
    http_lib
      .get(url)
      .then((res) => {
        dispatch(setLoader(false));
        return resolve(res);
      })
      .catch((err) => {
        dispatch(setLoader(false));
        return reject(err);
      });
  });
}
