export const rowsPerPage = 20

export const CourseList = [
    { "id": 1, "course_name": "MASTER OF BUSINESS ADMINISTRATION", "course_slug": "MBA" },
    { "id": 2, "course_name": "BACHELOR OF BUSINESS ADMINISTRATION", "course_slug": "BBA" },
    { "id": 3, "course_name": "BACHELOR OF TECHNOLOGY", "course_slug": "B.Tech" }
]

export const StudentGroups = ['G1', 'G2', 'G3']
export const ClassSections = ['A', 'B', 'C', 'D']

export const UploadInstructions = [
    'Upload .xls, .xlsx file only.',
    'Maximum file size should be 1MB.',
    'Data should be in required format only.',
    'Course ids are: mba = 1 bba = 2 btech = 3',
    'If uploading faculty subjects mapping make sure that for therory subjects group is null.',
    'If there is only one section please enter "A".',
    'If entering value in batch field please enter passing year.'
]