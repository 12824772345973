import { SET_OPEN } from "./snackBarActionTypes"

const initialState = {
    open: false,
    severity: 'success',
    msg: 'Snackbar'
}

export const snackbarReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_OPEN: return {
            ...state,
            open: action.payload.open,
            severity: action.payload.severity || 'success',
            msg: action.payload.msg || ''
        }
        default: return state
    }
}