import React, { useState } from "react";
import { useFormik } from "formik";
import { Button, Grid, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import {
  AddFacultySchema,
  FacultyInitialValues,
} from "../../../schemas/addFacultySchema";
import FileUploader from "../../common/file_uploader/fileUploader";
import { Post } from "../../../http/http";
import { useNavigate } from "react-router-dom";
import { ADD_FACULTY_URL } from "../../../constants/apiEndpoints";
import { useDispatch } from "react-redux";

function AddFaculty() {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const dispatch = useDispatch();

  const { handleSubmit, handleChange, handleBlur, values, touched, errors } =
    useFormik({
      initialValues: FacultyInitialValues,
      validationSchema: AddFacultySchema,
      onSubmit: ({ email, name, phone_number, designation }) => {
        const data = {
          is_file: false,
          faculty_data: [
            { email, name, phone_number, designation, password: "bpit12345" },
          ],
        };
        Post(ADD_FACULTY_URL, data, dispatch)
          .then(() => {
            navigate("/home/faculty");
          })
          .catch((err) => {
            console.log(err);
            if (err.response.status === 401) {
              localStorage.clear();
              alert("you are unauthorized");
              navigate("/login");
            }
            setError(JSON.stringify(err.response.data));
          });
      },
    });

  return (
    <>
      <Grid
        container
        spacing={2}
        component="form"
        noValidate
        onSubmit={(e) => handleSubmit(e)}
      >
        {/* row 1 */}
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            variant="outlined"
            label="Email"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.email}
            error={touched.email && errors.email !== undefined}
            helperText={
              touched.email && errors.email !== undefined ? errors.email : ""
            }
          />
        </Grid>

        {/* row 2 */}
        <Grid item xs={4}>
          <TextField
            required
            fullWidth
            variant="outlined"
            label="Name"
            name="name"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.name}
            error={touched.name && errors.name !== undefined}
            helperText={
              touched.name && errors.name !== undefined ? errors.name : ""
            }
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            required
            fullWidth
            variant="outlined"
            label="Phone Number"
            name="phone_number"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.phone_number}
            error={touched.phone_number && errors.phone_number !== undefined}
            helperText={
              touched.phone_number && errors.phone_number !== undefined
                ? errors.phone_number
                : ""
            }
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            variant="outlined"
            label="Designation"
            name="designation"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.designation}
            error={touched.designation && errors.designation !== undefined}
            helperText={
              touched.designation && errors.designation !== undefined
                ? errors.designation
                : ""
            }
          />
        </Grid>

        <Grid item xs={12} display="flex" flexDirection="column" my={2}>
          <Button type="submit" variant="contained">
            Submit
          </Button>
        </Grid>
        <Typography sx={{ color: "error.main" }}>{error}</Typography>

        <Grid item xs={12}>
          <Typography variant="h5" mb={4} align="center">
            OR
          </Typography>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <FileUploader
          sampleFileLink={
            process.env.PUBLIC_URL + "/upload_data_files/add_faculty_users.xlsx"
          }
          url={ADD_FACULTY_URL}
        />
      </Grid>
    </>
  );
}

export default AddFaculty;
