import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Divider, Grid, Paper, Tab } from '@mui/material';
import React from 'react';
import PostAddIcon from '@mui/icons-material/PostAdd';
import ListAltIcon from '@mui/icons-material/ListAlt'
import { ListToggles } from './listToggles';
import { AddToggles } from './addToggle';

export default function FeatureToggles() {
  const [value, setValue] = React.useState('1');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Grid item xs={12}>
      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
        <TabContext value={value}>
          <TabList onChange={handleChange} >
            <Tab value='1' icon={<ListAltIcon />} iconPosition='start' label="Toggles" />
            <Tab value='2' icon={<PostAddIcon />} iconPosition='start' label="Add Toggle" />
          </TabList>

          <Divider />

          <TabPanel value='1'><ListToggles /></TabPanel>
          <TabPanel value='2'><AddToggles /></TabPanel>

        </TabContext>
      </Paper>
    </Grid>
  )
}