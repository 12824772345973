import React from 'react';
// import { SUBJECTS_URL } from '../../../constants/api_endpoints';
import FileUploader from '../../common/file_uploader/fileUploader';
import { SUBJECTS_URL } from '../../../constants/apiEndpoints';

function AddSubjects() {
  // const postData = () => {
  //   http_lib.post(SUBJECTS_URL, {})
  //     .then(res => {
  //     })
  //     .catch(err => {
  //       console.log(err)
  //     })
  // }


  return (
    <>
      <FileUploader sampleFileLink={process.env.PUBLIC_URL + '/upload_data_files/add_subjects_format.xlsx'} url={SUBJECTS_URL} />
    </>
  )
}

export default AddSubjects;