import {
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { Get, Put } from "../../../http/http";
import { FEATURE_TOGGLE_URL } from "../../../constants/apiEndpoints";
import React, { useState, useEffect } from "react";
import TableHeaders from "../../common/tableHeaders";
import { rowsPerPage } from "../../../constants/constants";
import { useNavigate } from "react-router-dom";

export function ListToggles() {
  const [toggleList, setToggleList] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const tableColumns = ["S.No", "Toggle Name", "Status", "Action"];
  const handleRequest = (page_no) => {
    Get(FEATURE_TOGGLE_URL + `?page=${page_no}`, dispatch)
      .then((res) => {
        setToggleList(res.data["results"]);
        setCount(res.data["count"]);
        setPage(page_no);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          localStorage.clear();
          alert("you are unauthorized");
          navigate("/login");
        }
      });
  };

  const updateToggle = (obj) => {
    Put(FEATURE_TOGGLE_URL + `${obj["id"]}`, obj, dispatch)
      .then((res) => {
        handleRequest(page);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
        if (err.response.status === 401) {
          localStorage.clear();
          alert("you are unauthorized");
          navigate("/login");
        }
        handleRequest(page);
      });
  };

  useEffect(() => {
    handleRequest(1);
    // eslint-disable-next-line
  }, []);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
    handleRequest(newPage + 1);
  };

  const handleToggleChange = (val, obj) => {
    obj["is_active"] = val;
    updateToggle(obj);
  };

  return (
    <>
      <Paper sx={{ width: "100%", mb: 1 }}>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHeaders columns={tableColumns} />
            <TableBody>
              {toggleList.map((toggle, key) => (
                <TableRow hover key={key}>
                  <TableCell>{toggle["id"]}</TableCell>
                  <TableCell>{toggle["featureName"]}</TableCell>
                  <TableCell>
                    {toggle["is_active"] ? "ACTIVE" : "INACTIVE"}
                  </TableCell>
                  <TableCell>
                    <Switch
                      onChange={(e) =>
                        handleToggleChange(e.target.checked, toggle)
                      }
                      defaultChecked={toggle["is_active"]}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          component="div"
          rowsPerPageOptions={[]}
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
        />
      </Paper>
    </>
  );
}
