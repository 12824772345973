import { SET_LOADER } from "./loaderActionTypes"

const initialState = {
    show: false
}

export const loaderReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_LOADER: return {
            ...state,
            show: payload,
        }
        default: return state
    }
}