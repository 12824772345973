import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { HashRouter as BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './components/home/home';
import Student from './components/home/student/student';
import Login from './components/login/login';
// import { Dashboard } from '@mui/icons-material';
import Loader from './components/common/loader/loader';
import Subjects from './components/home/subjects/subjects';
import Faculty from './components/home/faculty/faculty';
import { Provider } from 'react-redux';
import store from "./redux/store";
import NotFound from './components/common/notFound';
import FacultySubjectsMapping from './components/home/facultySubjectsMapping/facultySubjectsMapping';
import FeatureToggles from './components/home/featureToggles/featureToggles';

export default function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: light)');

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light',
        },
      }),
    [prefersDarkMode],
  );

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Loader />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/home" element={<Home />}>
              {/* <Route path="dashboard" element={<Dashboard />} /> */}
              <Route path="students" element={<Student />} />
              <Route path="subjects" element={<Subjects />} />
              <Route path="faculty" element={<Faculty />} />
              <Route path="faculty-subjects-mapping" element={<FacultySubjectsMapping />} />
              <Route path="feature-toggles" element={<FeatureToggles />} />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
        {/* <Button sx={{ float: "right", position:"absolute" }} >
        LightMode
      </Button> */}
      </ThemeProvider>
    </Provider>
  );
}
