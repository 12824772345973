import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom'
import HomeIcon from '@mui/icons-material/Home';

export default function NotFound() {
    return (
        <Box
            sx={{
                width: '100vw',
                height: '100vh',
                backgroundColor: '#e3f2fd'
            }}
            display='flex'
            justifyContent='center'
            alignItems='center'
            flexDirection='column'
        >
            <Typography variant='h1'>404</Typography>
            <Typography variant='h3'>Not Found</Typography>
            <Typography variant='h6'>The page you are looking for does not exists</Typography>
            <Button component={Link} size='large' variant='contained' to='/home' endIcon={<HomeIcon />}>Go back Home</Button>
        </Box>
    )
}