import * as Yup from "yup";

export const AddFacultySchema = Yup.object({
  email: Yup.string().email().required("Please enter the Email!"),
  name: Yup.string().min(3).required("Please enter the Name!"),
  phone_number: Yup.number().min(10).required("Please enter the Phone Number!"),
  designation: Yup.string().min(2).max(50)
});

export const FacultyInitialValues = {
  email: '',
  name: '',
  phone_number: '',
  designation: ''
}