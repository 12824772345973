export const INTERCEPTOR_URL = "https://techcse2020.pythonanywhere.com";
export const LOGIN_URL = "/auth/admin/login/";
export const LOGOUT_URL = "/auth/logout/";
export const BRANCH_URL = "/branch/";
export const COURSE_URL = "/course/";
export const STUDENTS_LIST_URL = "/student/profile/search?query=";
export const SUBJECTS_URL = "/subjects/";
export const ADD_STUDENT_URL = "/student/profile/add/";
export const ADD_FACULTY_URL = "/auth/register/";
export const ADD_FACULTY_SUBJECTS_MAPPING_URL = "/faculty/subjects/";
export const FEATURE_TOGGLE_URL = "/feature_toggle/"