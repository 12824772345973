import React from 'react';
import { useSelector } from 'react-redux';
import Backdrop from '@mui/material/Backdrop';
import './loader.css';

export default function Loader() {
  const { show } = useSelector(state => state.loader)

  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 20 }}
        open={show}
      >
        <div className="loading-1">
          <i></i>
          <i></i>
          <i></i>
          <i></i>
          <i></i>
        </div>
      </Backdrop>
    </div>
  );
}
